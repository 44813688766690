import { useAuth0 } from '@auth0/auth0-react'
import { Country, Role, RoleFlag } from 'constants/'
import { useEffect, useMemo } from 'react'
import { DeliveryModel } from 'types'
import { logFactory } from 'utils'

type RoleFlagsMap = Record<RoleFlag, boolean>

interface UserContext {
  accountId?: string
  allowedCountries: Country[]
  allowedDeliveryModels: DeliveryModel[]
  email?: string
  isAuthenticated: boolean
  name?: string
  roleFlags?: RoleFlagsMap
}

const DOMAIN = 'https://hear.com'

interface Auth0User {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [DOMAIN]: Record<string, any>
  email: string
  name: string
}

const log = logFactory('useUser')

const FALLBACK_ALLOWED_COUNTRIES: Country[] = ['US']

const useUser = (): UserContext => {
  const auth0Context = useAuth0()
  const user = auth0Context.user as Auth0User | undefined
  const email = user?.email
  const meta = user?.[DOMAIN]
  const name = user?.name
  const accountId = meta?.user_metadata?.account_id
  const roles = meta?.roles
  const isAuthenticated = Boolean(user)
  const metaAllowedCountries: Country[] | undefined = meta?.user_metadata?.allowed_countries
  const allowedDeliveryModels: DeliveryModel[] = meta?.user_metadata?.allowed_delivery_models ?? []

  const allowedCountries = useMemo(() => {
    if (metaAllowedCountries?.length) {
      return metaAllowedCountries
    }

    return isAuthenticated ? FALLBACK_ALLOWED_COUNTRIES : []
  }, [isAuthenticated, metaAllowedCountries])

  const userContext: UserContext = useMemo(() => {
    const baseContext: UserContext = {
      accountId,
      allowedCountries,
      allowedDeliveryModels,
      email,
      isAuthenticated,
      name,
    }

    /**
     * NOTE: During local development Auth0 will not get back roles
     * ...unless we prompt the user with a consent modal
     * https://github.com/auth0/auth0-react/issues/65#issuecomment-656543646
     * https://auth0.com/docs/authorization/user-consent-and-third-party-applications
     */
    if (!roles) {
      return baseContext
    }

    const rolesSet: Set<Role> = new Set(roles)
    const isAdmin = rolesSet.has(Role.ADMIN)
    const isEmployeeFitter = rolesSet.has(Role.EMPLOYEE_FITTER) || rolesSet.has(Role.INTERNAL_TA_FITTER)
    const isPartnerFitter = rolesSet.has(Role.PARTNER_FITTER) || rolesSet.has(Role.EXTERNAL_TA_FITTER)
    const hasInternalFitterRole = rolesSet.has(Role.INTERNAL_FITTER)
    const isFitter = isEmployeeFitter || isPartnerFitter || hasInternalFitterRole
    const isInternalFitter = isAdmin || isEmployeeFitter || hasInternalFitterRole
    const isOperations = rolesSet.has(Role.OPS) || rolesSet.has(Role.OPERATIONS)

    const roleFlags = {
      isAdmin,
      isEmployeeFitter,
      isFitter,
      isInternalFitter,
      isOperations,
      isPartnerFitter,
    }

    return {
      ...baseContext,
      roleFlags,
    }
  }, [accountId, allowedCountries, email, isAuthenticated, name, roles])

  useEffect(() => {
    if (!isAuthenticated) return

    log(`👤 Auth0 user`, user)
  }, [isAuthenticated])

  return userContext
}

export type { RoleFlagsMap }
export { useUser }
