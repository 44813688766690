import { useFeatureFlags } from '@audibene/ta.react-feature-flags'

type FeatureFlagName =
  | 'ADD_APPOINTMENT_NOTES_TIMELINE_V2'
  | 'APP_INSTALLATION_STATUS'
  | 'APPOINTMENT_DASHBOARD_V2'
  | 'COMPLETE_APPOINTMENT_OUTCOME'
  | 'DASHBOARD_FOCUS_CUSTOMERS'
  | 'DASHBOARD_JOIN_CALL_BUTTON'
  | 'DASHBOARD_RESCHEDULE_BUTTON'
  | 'DEVICES_TAB'
  | 'DISPLAY_CUSTOMER_PROFILE_CLIENT'
  | 'DISPLAY_DOCUMENT_CLIENT'
  | 'DISPLAY_HEARING_TEST_CLIENT'
  | 'DISPLAY_MEDIA_ITEM_DATES'
  | 'DISPLAY_OPPORTUNITY_INFO_SECTION'
  | 'DISPLAY_WEARING_TIME_FOCUS_REASON'
  | 'EDIT_NOTES_TIMELINE_V2'
  | 'EXPORT_AUDIOGRAM'
  | 'FLOW_CLIENT_VNEXT'
  | 'INSURANCE_PROFILE_DOWNLOAD'
  | 'NOTES_TIMELINE_V2'
  | 'ORDER_TAB_CANCEL_ORDERS'
  | 'PROGRESS_BAR_V2'
  | 'SALES_ORDER_FORM'
  | 'SCHEDULE_APPOINTMENT'
  | 'SETTINGS_PAGES'
  | 'SHOULD_USE_DEVICE_SERVICE'
  | 'TEST_FEATURE_FLAGS'
  | 'TIMELINE_RESCHEDULE_BUTTON'

type Uuid = `${string}-${string}-${string}-${string}-${string}`

// Feature flag key values can be found here: https://app.launchdarkly.com/ta-team-america/production/features
const featureFlagNameToKeyMap: Record<FeatureFlagName, Uuid> = {
  ADD_APPOINTMENT_NOTES_TIMELINE_V2: '53d2e905-3903-4c37-94f8-7a60c17500e7',
  APP_INSTALLATION_STATUS: 'f85c771e-bc70-48d6-bad0-51bf463980e4',
  APPOINTMENT_DASHBOARD_V2: 'fa55ec74-e233-48e8-86ac-ebe155edfb09',
  COMPLETE_APPOINTMENT_OUTCOME: '77adc110-f644-4571-871f-400bd2b7c907',
  DASHBOARD_FOCUS_CUSTOMERS: '21fcbd40-fbdf-4661-9465-e123d5781398',
  DASHBOARD_JOIN_CALL_BUTTON: '0a6895c5-2c2e-47af-8452-2a3b614bbd85',
  DASHBOARD_RESCHEDULE_BUTTON: '5b156c42-8594-43fb-be56-313cad3dd1d5',
  DEVICES_TAB: '3c292c94-47c0-45a0-aa88-0c15fec4ce66',
  DISPLAY_CUSTOMER_PROFILE_CLIENT: '556a6609-3334-45f5-a586-ff976bacc17d',
  DISPLAY_DOCUMENT_CLIENT: '57007004-40af-43bc-8720-a074ecfeadb9',
  DISPLAY_HEARING_TEST_CLIENT: 'e3c94109-0eeb-4091-bd66-c36a263e6030',
  DISPLAY_MEDIA_ITEM_DATES: '8b0fd031-c74c-4f40-894f-21109c5748ee',
  DISPLAY_OPPORTUNITY_INFO_SECTION: 'c1be3591-c966-453e-abae-db5151d5c23d',
  DISPLAY_WEARING_TIME_FOCUS_REASON: '35ce4d74-204b-4aa7-a346-ff4509fd7e96',
  EDIT_NOTES_TIMELINE_V2: '5da234f7-3ab4-435e-b9bb-a23d3e4c6c40',
  EXPORT_AUDIOGRAM: 'ffad2030-19fe-4cb4-a92e-6ba6cbb00efb',
  FLOW_CLIENT_VNEXT: '3f1bd6aa-cd0a-4095-8b25-4a8abff469b5',
  INSURANCE_PROFILE_DOWNLOAD: '89cce9ea-dd48-4264-a4da-4bce54a7a177',
  NOTES_TIMELINE_V2: 'e9798c8b-94b9-42d0-b25e-b350c7c8838b',
  ORDER_TAB_CANCEL_ORDERS: '6c6a226e-6795-487d-bc84-bbe0fc70270d',
  PROGRESS_BAR_V2: 'bb7d0919-7a64-4036-9042-f189575b8f91',
  SALES_ORDER_FORM: 'cf9df706-292f-45fa-ad68-52a95875a8bc',
  SCHEDULE_APPOINTMENT: 'abe7ab80-cb4e-4a9a-84d2-12124867693e',
  SETTINGS_PAGES: '80a25283-a827-45a1-99d4-e12ca6809e90',
  SHOULD_USE_DEVICE_SERVICE: '7c5dabbd-2df7-4239-aea1-4a9865596cf3',
  TEST_FEATURE_FLAGS: '3b35dc23-6f42-4d00-8848-455e4ebf7bff',
  TIMELINE_RESCHEDULE_BUTTON: 'f218a508-22b4-4c9b-9b78-301f5e7fee5e',
} as const

export { featureFlagNameToKeyMap, useFeatureFlags }
export type { FeatureFlagName }
