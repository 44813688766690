import { RequestOptions } from 'mixpanel-browser'
import { useCallback, useRef } from 'react'
import { ENVIRONMENT } from 'config'
import { EventType } from 'constants/'
import type { AnyEventData } from 'types'
import { logFactory } from 'utils'
import { useMixpanel } from '../useMixpanel'

interface Options {
  data?: AnyEventData
  options?: RequestOptions
}

interface EventTrackingContext {
  track: (type: EventType, options?: Options) => void
  /** Used to make grouping sibling track calls in Mixpanel easier */
  traceId: string
}

const log = logFactory('hooks', 'useEventTracking')

const parseEventData = (data?: AnyEventData) => ({
  ...data,
  environment: ENVIRONMENT,
})

const noop = (type: EventType, data?: AnyEventData) => {
  const event = parseEventData(data)

  log(`❌ Mixpanel not ready. Not tracking "${type}" event`, event)
}

const useEventTracking = (): EventTrackingContext => {
  const { mixpanel } = useMixpanel()
  const traceId = useRef<string>(crypto.randomUUID())

  const track = useCallback(
    (type: EventType, { data, options }: Options = {}) => {
      if (!mixpanel) return noop(type, data)

      const event = parseEventData(data)

      log(`🐾 Tracking "${type}" event`, event)

      return mixpanel.track(type, event, options)
    },
    [mixpanel]
  )

  return { traceId: traceId.current, track }
}

export { useEventTracking }
